import { Amplify, Auth } from 'aws-amplify';
import { DEFAULT_LOCALE, DEFAULT_LC_BUILDER } from "./constants/Constants";
import AppInitWrapper from './components/AppInitWrapper';
import AuthenticateUser from './components/AuthenticateUser';
import { MbmProvider } from "@amzn/react-arb-tools";
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { Spinner } from '@amzn/awsui-components-react';
import store from './stores/store';
import '@amzn/katal-components/styles.css';
import './i18n';
import './styles/index.scss';
const arbManifest = require('../build/i18n/arbManifest');

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

console.log(`index.tsx`);
console.log('Stage is ' + 'beta');
console.log('AWS_REGION is ' + 'us-east-1');
console.log('IDENTITY_POOL_DOMAIN is ' + 'gso-silenciov2-beta.auth.us-east-1.amazoncognito.com');
console.log('IDENTITY_POOL_ID is ' + 'us-east-1:1ba9d47c-3bfa-470f-ad36-356953c16b27');
console.log('USER_POOL_ID is ' + 'us-east-1_o2Cbv8vec');
console.log('BuildTime is ' + '11/15/2024, 7:50:16 AM');

// @ts-ignore because this is a replacement var
if ('beta' == 'beta' || 'beta' == 'gamma') {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

try {
  const katalBuildTime = '11/15/2024, 7:50:16 AM';
  const storedBuildTime = localStorage.getItem('BuildTime');
  if (!storedBuildTime || storedBuildTime != katalBuildTime) {
    console.log('Updating local storage.');
    localStorage.clear(); //Allow updating auth claims
    localStorage.setItem('BuildTime', katalBuildTime);
  }
} catch (err) {
  console.log('Error checking local storage for BuildTime.');
}

const callbackUrl = window.location.origin;
const config = {
  oauth: {
    domain: 'gso-silenciov2-beta.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code',
  },
  // Cognito
  aws_cognito_identity_pool_id: 'us-east-1:1ba9d47c-3bfa-470f-ad36-356953c16b27',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_o2Cbv8vec',
  aws_user_pools_web_client_id: '299c3489tkl4ivvn5mm0i08jk5',
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://api.beta.silencio.gso.amazon.dev/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_endpoint: 'https://api.beta.silencio.gso.amazon.dev/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
    }),
  },
};
Amplify.configure(config);

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE).withDefaultLocale(DEFAULT_LOCALE).build(),
  urlPrefix: '/i18n',
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppInitWrapper>
        <React.Suspense fallback={<Spinner size='large' />}>
          <MbmProvider {...mbmOptions}>
            <AuthenticateUser />
          </MbmProvider>
        </React.Suspense>
      </AppInitWrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);