import * as APIt from "../API";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { Paths, Regions } from '../constants/Constants';
import { getSites, listFacmanUserSites, listSIGInfraUserSites } from '../graphql/queries';
import { debug, getPACSDataAPIStage } from './commonUtils';
import { createUserAction } from './UserActionsUtils';
import { UserActionNames } from 'src/constants/Constants';

export interface UserSitesParamsInterface {
  loginUsername: string | undefined;
  phySecMember: boolean,
  phySecRegions: string[] | undefined,
  sideTeam: boolean,
  sigAdmin: boolean,
  facmanParams: {
    groupname: string;
    employeeId: number;
    regions?: string[];
    sitename: string;
    username: string;
  }
};

const querySigInfraUserSites = async (stage: string, username: string): Promise<APIt.SIGInfraSite[]> => {
  debug(`querySigInfraUserSites(): stage is ${stage} username is ${username}`);

  let sigSites: APIt.SIGInfraSite[] = [];

  for (let region of Regions) {
    debug(`querySigInfraUserSites(): region is ${region}`);
    const sigSitesResponse = await API.graphql(graphqlOperation(listSIGInfraUserSites,
      {
        path: `${Paths.SiteFinder}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?login=${username}&OutputFormat=json`
      })) as GraphQLResult<APIt.ListSIGInfraUserSitesQuery>;
    debug(`querySigInfraUserSites(): sigSitesResponse is ${JSON.stringify(sigSitesResponse)}`);
    let sigInfraSites = sigSitesResponse.data?.listSIGInfraUserSites as APIt.SIGInfraSite[];
    sigInfraSites = sigInfraSites.map(site => { return {...site, Region: region} } );
    sigSites = [...sigSites, ...sigInfraSites];
  }
  return sigSites;
};

const queryAllSites = async (stage: string, regions: string[]): Promise<APIt.UserPrefSite[]> => {
  debug(`queryAllSites(): stage is ${stage} regions is ${regions}`);

  let allSites: APIt.UserPrefSite[] = [];

  for (let region of regions) {
    debug(`queryAllSites(): region is ${region}`);
    const sitesResponse = await API.graphql(graphqlOperation(getSites,
      {
        path: `${Paths.GetSites}/region/${region}/stage/${getPACSDataAPIStage(stage)}/?OutputFormat=json`
      })) as GraphQLResult<APIt.GetSitesQuery>;
    debug(`queryAllSites(): sitesResponse is ${JSON.stringify(sitesResponse)}`);
    const sites = sitesResponse.data?.getSites as APIt.Site[];
    const mappedSites: APIt.UserPrefSite[] = sites.map(site => { return { __typename: "UserPrefSite", sitename: site.site_code, siteRegion: region} } );
    allSites.push(...mappedSites);
    debug(`queryAllSites(): allSites length is ${allSites.length}`);
  }
  return allSites;
};

export const fetchUserSites = async (params: UserSitesParamsInterface): Promise<APIt.UserPrefSite[]> => {
  debug(`fetchUserSites(): params is ${JSON.stringify(params)}`);

  let stage = 'beta';
  debug(`fetchUserSites(): stage is ${stage} ${stage.toUpperCase()}`);

  let userSites: APIt.UserPrefSite[] = [];
  if (!params.loginUsername) return userSites;

  try {
    createUserAction({
      actionName: UserActionNames.RefreshSites,
      username: params.loginUsername,
      parameters: JSON.stringify(params)
    });

    if (params.sideTeam || params.sigAdmin || params.phySecMember) {
      if (params.phySecRegions && params.phySecRegions.length > 0) {
        debug(`fetchUserSites(): fetching all sites for regions: ${JSON.stringify(params.phySecRegions)}`);
        userSites = await queryAllSites(stage, params.phySecRegions);
      } else {
        debug('fetchUserSites(): fetching all sites');
        userSites = await queryAllSites(stage, Regions);
      }
    } else {
      debug(`fetchUserSites(): params.facmanParams is ${JSON.stringify(params.facmanParams)}`);
      const facmanSitesResponse = await API.graphql(graphqlOperation(listFacmanUserSites,
        {
          sitename: params.facmanParams.sitename,
          groupname: params.facmanParams.groupname,
          regions: params.facmanParams.regions,
          username: params.facmanParams.username,
          employeeId: params.facmanParams.employeeId
        })) as GraphQLResult<APIt.ListFacmanUserSitesQuery>;
      debug(`fetchUserSites(): facmanSitesResponse is ${JSON.stringify(facmanSitesResponse)}`);
      const facmanSitesResponseError = facmanSitesResponse.data?.listFacmanUserSites?.error;
      if (facmanSitesResponseError) throw(facmanSitesResponseError);

      const facmanSites = facmanSitesResponse.data?.listFacmanUserSites?.sites as APIt.FacmanSite[];
      userSites = facmanSites.filter(fs => fs.siteName !== undefined && fs.region !== 'unknown').map(fs => {
        return { __typename: 'UserPrefSite', sitename: fs.siteName!, siteRegion: fs.region! }
      });
    }

    const sigSites = await querySigInfraUserSites(stage, params.loginUsername);
    debug(`fetchUserSites(): sigSites is ${JSON.stringify(sigSites)}`);
    const sigSitesMapped: APIt.UserPrefSite[] = sigSites.filter(ss => ss.SiteCode !== undefined && ss.Region !== 'unknown')
      .map(ss => { return { __typename: 'UserPrefSite', sitename: ss.SiteCode!, siteRegion: ss.Region } });
    userSites.push(...sigSitesMapped);
    debug(`fetchUserSites(): userSites is ${JSON.stringify(userSites)}`);

    const uniqueSites = Array.from(new Set(userSites.map(v => JSON.stringify(v)))).map(v => JSON.parse(v)).sort((a, b) => {
      if (a.sitename === b.sitename) {
        return 0;
      } else if (a.sitename > b.sitename) {
        return 1;
      } else {
        return -1;
      }
    });
    debug(`fetchUserSites(): uniqueSites is ${JSON.stringify(uniqueSites)}`);
    createUserAction({
      actionName: UserActionNames.RefreshSitesResult,
      username: params.loginUsername,
      parameters: JSON.stringify({params, sites: uniqueSites})
    });

    return uniqueSites;
  } catch(error) {
    console.error(`fetchUserSites(): error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
    createUserAction({
      actionName: UserActionNames.RefreshSitesError,
      username: params.loginUsername,
      parameters: JSON.stringify({error, params})
    });
    throw error;
  }
}

export enum Privileges {
  all = 'All',
  maskInputs = 'Mask Inputs'
};

export const fetchUserSitePrivs = async (employeeId: number, username: string, sitename: string, groupname: string = ''): Promise<Privileges[]> => {
  debug(`fetchUserSitePrivs(): username is ${username} sitename is ${sitename} groupname is ${groupname}`);

  const userSitePrivs: Privileges[] = [];

  if (!username || !sitename) return userSitePrivs;

  let stage = 'beta';
  debug(`fetchUserSitePrivs(): stage is ${stage} ${stage.toUpperCase()}`);

  try {
    const facmanSitesResponse = await API.graphql(graphqlOperation(listFacmanUserSites,
      {
        sitename: sitename,
        groupname: groupname,
        username: username,
        employeeId: employeeId
      })) as GraphQLResult<APIt.ListFacmanUserSitesQuery>;
    debug(`fetchUserSitePrivs(): facmanSitesResponse is ${JSON.stringify(facmanSitesResponse)}`);
    let facmanSites = facmanSitesResponse.data?.listFacmanUserSites?.sites as APIt.FacmanSite[];
    debug(`fetchUserSitePrivs(): facmanSites is ${JSON.stringify(facmanSites)}`); 
    if (facmanSites.length > 0) {
      userSitePrivs.push(Privileges.all);
      debug(`fetchUserSitePrivs(): userSitePrivs is ${JSON.stringify(userSitePrivs)}`);
      return userSitePrivs;
    } 
    const sigSites = await querySigInfraUserSites(stage, username);
    debug(`fetchUserSitePrivs(): sigSites is ${JSON.stringify(sigSites)}`);
    if (sigSites.map(ss => ss.SiteCode).includes(sitename)) userSitePrivs.push(Privileges.maskInputs);
    debug(`fetchUserSitePrivs(): userSitePrivs is ${JSON.stringify(userSitePrivs)}`);
    return userSitePrivs;
  } catch(error) {
    console.error(`fetchUserSitePrivs(): error is ${error} JSON.stringify: ${JSON.stringify(userSitePrivs)}`);
    throw error;
  }
}